import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { User } from '../models/user';
import { AlertController } from 'mm-ui';
import { tap } from 'rxjs/operators';
import { error } from '@rxweb/reactive-form-validators';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(private user: User) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let ignore: boolean = !!req.headers.get('ignoreInterceptorRedirect');
    let useReportToken = !!req.headers.get('useReportToken');
    let reportAccessToken: any = localStorage.getItem('reportToken');

    if (this.user.isAuthenticated()) {
      const dupReq = req.clone(
        {
          setHeaders:
          {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + this.user.getToken(),
            'Content-Type': 'application/json',
          }
        }
      );

      const dup = req.clone(
        {
          setHeaders:
          {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          }
        }
      );

      const repToken = req.clone(

        {
          setHeaders:
          {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + reportAccessToken,
            'Content-Type': 'application/json',
          }
        }
      );

      if (ignore) {
        return next.handle(dup);

      }
      else if (useReportToken && reportAccessToken) {
        return next.handle(repToken)

      }
      else {
        return next.handle(dupReq);
      }

    }

    return next.handle(req);
  }
}

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private user: User, private router: Router, private alertController: AlertController) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let ignore: boolean = !!req.headers.get('ignoreInterceptorRedirect');
    let useReportToken = !!req.headers.get('useReportToken');
    let reportAccessToken: any = localStorage.getItem('reportToken');

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {

        const status = err.status;

        if (status === 401) {
          localStorage.removeItem('reportToken')
          if (ignore || useReportToken) {
            let message = { message: 'Inconsistência nos dados para o download de relatórios', status: '401' };
            throw message
          } else if (this.router.url.indexOf('/clients') >= 0) {
            this.user.deleteToken();
            localStorage.setItem('redirectTo', this.router.url);
            this.alertController.configure({
              error: 'login'
            })
            this.alertController.open();
            this.router.navigate(['/login'], { queryParams: { signedout: true } });
          }
        } else {
          console.error(err);
        }
      }
    }));
  }
}

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ]
})
export class InterceptorModule { }

