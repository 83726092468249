    <section class="mm-box-container dark key-value-config">
      <div class="add-new">
        <button class="btn btn-as-link" (click)="addNew()">
          Adicionar novo parâmetro
        </button>
      </div>
      <div class="content">
        <ng-scrollbar>
          <div class="form-item" *ngFor="let form of forms?.controls; let i = index">
            <mmp5-fieldset-haruki
              [formControl]="form.get('type')"
              [label]="'Tipo de Parâmetro'"
              [selectOptions]="typeOptions">
            </mmp5-fieldset-haruki>
            <mmp5-fieldset-haruki
              *ngIf="validateShowNameField(form.get('type').value)"
              [formControl]="form.get('name')"
              [label]="'Chave'">
            </mmp5-fieldset-haruki>
            <mmp5-fieldset-haruki
              *ngIf="form.get('type').value === 'EXTERNAL_CONNECTOR'"
              [formControl]="form.get('externalConnector').get('type')"
              [selectOptions]="externalConnectorTypeOptions"
              [label]="'Tipo Conector Externo'">
            </mmp5-fieldset-haruki>
            <mmp5-fieldset-haruki
              *ngIf="form.get('type').value === 'NOT_TO_INDEX'"
              class="value"
              [formControl]="form.get('value')"
              [label]="'Valor'"
              [selectOptions]="notToIndexTypeOptions">
            </mmp5-fieldset-haruki>
            <button title="Excluir Configuração" class="btn btn-danger" (click)="removeForm(i)">
              <i class="fa fa-trash"></i>
            </button>
            <div class="value-content">
              <mmp5-fieldset-haruki
                *ngIf="validateShowValueField(form.get('type').value)"
                class="value"
                [formControl]="form.get('value')"
                [label]="'Valor'"
                [type]="'textarea'"
                [height]="'150px'">
              </mmp5-fieldset-haruki>
              <div *ngIf="form.get('type').value === 'EXCLUDE_PAGES_FROM_INDEX'" class="exclude-page-selector">
                <ng-content></ng-content>
              </div>
              <div *ngIf="form.get('type').value === 'EXTERNAL_CONNECTOR'" class="external-connector-config">
                <ng-template [ngIf]="form.get('externalConnector').get('type').value === 'BIG_QUERY'">
                  <mmp5-fieldset-haruki
                    [formControl]="form.get('name')"
                    [label]="'Nome do dataset'">
                  </mmp5-fieldset-haruki>
                  <mmp5-fieldset-haruki
                    [formControl]="form.get('externalConnector').get('datasetName')"
                    [label]="'ID do dataset'">
                  </mmp5-fieldset-haruki>
                  <mmp5-fieldset-haruki
                    [formControl]="form.get('externalConnector').get('googleCloudApiKey')"
                    [label]="'Chave Api Google'">
                  </mmp5-fieldset-haruki>
                  <mmp5-fieldset-haruki
                    [formControl]="form.get('externalConnector').get('googleCloudProjectId')"
                    [label]="'ID do projeto Google Cloud'" >
                  </mmp5-fieldset-haruki>
                  <mmp5-fieldset-haruki
                    [formControl]="form.get('externalConnector').get('datasetDescription')"
                    [type]="'textarea'"
                    [label]="'Descrição do dataset'"
                    [height]="'150px'">
                  </mmp5-fieldset-haruki>
                </ng-template>
              </div>
              <button *ngIf="form.get('type').value === 'ASSISTANT_AVATAR'" class="btn btn-primary" (click)="emitOpenMediaPicker(form)">
                Selecionar avatar
              </button>
            </div>
          </div>
        </ng-scrollbar>
      </div>
    </section>

