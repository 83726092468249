import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, AfterViewInit, HostBinding, AfterViewChecked, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';

import { FieldsetHarukiSelectOption } from './fieldset-haruki-select-option';

import { isDescendant, toDataURL, isImage } from '../utils/traversing';

import * as _ from 'lodash';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mmp5-fieldset-haruki',
  templateUrl: './fieldset-haruki.component.html',
  styleUrls: ['./fieldset-haruki.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldsetHarukiComponent),
      multi: true
    }
  ]
})
export class FieldsetHarukiComponent implements ControlValueAccessor, OnInit, AfterViewChecked, AfterViewInit {
  @Input() value: any = '';
  @Input() formControlValue: string;
  @Input() formControl: UntypedFormControl = new UntypedFormControl(null)
  @Input() key = '';
  @Input() label: HTMLLabelElement = null;
  @Input() type = 'text';
  @Input() helperText = null;
  @Input() mask = null;
  @Input() showError = true;
  @Input() selectOptions: Array<FieldsetHarukiSelectOption> = null;
  @Input() canSearchList = false;
  @Input() maxLength: number = null;
  @Input() max: number = null;
  @Input() min: number = null;
  @Input() multipleFiles = false;
  @Input() filePreview = true;
  @Input() fileDrop = false;
  @Input() fileDropLabel = 'Drop file(s)';
  @Input() listFiles = true;
  @Input() tooltip: string = "";
  @Input() tooltipIcon: string = "";
  @Input() tooltipCheck: string;
  @Input() link: string = "";
  @Input() linkText: string = "";
  @Input() class: string = "";
  @Input() contentClass: string = "";
  @Input() check: boolean = false;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() options: Array<string> = [];
  @Input() valDate: string;
  @Input() validator: string
  @Input() fileName: string
  @Input() objectRadio: boolean
  @Input() checkboxValue: string
  @Input() ngFocus: boolean = false;
  @Input() position: string = 'right';
  @Input() positionDatepicker: string = '';
  @Input() accept: string;
  @Input() checkedgroup: string;
  @Input() small: boolean = false
  @Input() icon: Array<string> = ['icon', 'icon-calendario']
  @Input() rows: number;
  @Input() searchButton: boolean = false;
  @Input() sliderOptions: any = {};
  @Input() patterns: any;
  @Input() specialCharacters: string[];
  @Input() dropSpecialCharacters: boolean = false;
  @Input() clearable = true
  @Input() colorPallet = 'primary'
  @Input() autocomplete: string
  @Input() double = false;
  @Input() dropdown: any[];
  @Input() dropdownName: string;
  @Input() dropdownPosition: 'after' | 'before';
  @Input() dropdownDisabled: boolean;
  @Input() readOnly: boolean;
  @Input() hideX: boolean;
  @Input() returnBase64: boolean = false;
  @Input() numberStepper: number = 1;
  @Input() darkBG: boolean;
  @Input() fileButtonIcon: boolean
  @Input() radioGroupStyle;
  @Input() height: string = 'auto';
  @Input('show-password') showPassword:boolean = false

  @Output() expand = new EventEmitter();
  @Output('keyup') keyup = new EventEmitter();
  @Output('focus') focusEmitter = new EventEmitter();
  @Output('blur') blurEmitter = new EventEmitter();
  @Output('changeControl') changeControl = new EventEmitter();
  @Output('dropdownSelected') dropdownSelected = new EventEmitter();
  @Output('showHidePassword') showHidePassword = new EventEmitter();


  hasFile: string;
  segment: string;
  notRenderizedImg: boolean = false;
  iconEye = 'fa-eye-slash'

  @ViewChild('dp') datepicker: any;
  @ViewChild('defaultinput') defaultinput: any;

  @HostBinding('attr.data-expand') dataExpand = false;

  hideCalendar = true;

  selectValue;

  isImage = isImage;
  color: string;
  index: number;
  checkgroup;
  phoneMask: string;
  dateMask = '00/00/0000'

  propagateChange = (_: any) => { };
  passwordVisible: boolean;

  constructor() {

  }

  writeValue(value: any) {

    if (value !== undefined) {
      this.value = value;
    }
  }

  clearNgSelect() {
    this.formControl.setValue('')
  }

  onInput(e) {

  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  setFilled() {
  }

  ngAfterViewChecked(): void {
    this.setFilled()
    this.removeTooltipFromDatePicker()
  }

  ngOnInit() {
    this.changeVal()
    this.onChangeValue()
  }

  ngAfterViewInit() {
  }

  selectDropdown(drop) {
    this.dropdownSelected.emit(drop)
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.formControl && this.selectOptions) {
      let value = this.formControl.value;

      let labelValue = this.getSelectOptionsLabel(value)

      if (this.selectValue != labelValue) {
        this.selectValue = labelValue;
      }
    }
    if (changes.selectOptions && this.selectOptions) {
      let value = this.getSelectOptionsLabel(this.formControl.value);

      this.selectValue = value;
    }
    if (changes.formControl && this.type == 'radio') {
      this.value = this.formControl.value;
    }
  }

  getSelectOptionsLabel(value: any) {

    if (this.selectOptions.length) {
      let values = this.selectOptions.filter(val => val.value === value);
      return values.length > 0 ? values[0] : '';
    } else {
      return ''
    }
  }

  removeTooltipFromDatePicker() {
    if (this.type === 'date') {
      let monthPickerSelect = document.querySelectorAll("select.form-select[aria-label='Select month']");
      monthPickerSelect.forEach(item => item.setAttribute('title', 'Selecione o mês'))

      let yearPickerSelect = document.querySelectorAll("select.form-select[aria-label='Select year']");
      yearPickerSelect.forEach(item => item.setAttribute('title', 'Selecione o ano'))
    }
  }

  changeVal() {
    if (this.selectOptions) {
      this.setSelectOptionsLabel(this.formControl.value);
    } else {
      if (this.formControl) {
        if (this.formControl.value) {
          this.value = this.formControl.value;
          if (this.type == 'phone') {
            this.switchPhoneMask(this.value.length)
          }
        }
      }
    }
  }

  private setSelectOptionsLabel(value) {
    const selected = this.selectOptions.filter(s => s.value === value);
    if (selected.length > 0) {
      this.selectValue = selected[0].label;
    }
  }

  onChangeValue() {
    if (this.formControl) {
      this.formControl.registerOnChange(() => {
        this.changeControl.emit(this.formControl.value);
        this.changeVal()
      })
      if (this.type === 'colorPicker') {
        this.color = this.formControl.value;
      }
    }
    if (this.formControlValue) {
      this.formControl.setValue(this.formControlValue);
    }
  }

  getChecked(opt) {
    return this.checkedgroup ? this.checkedgroup.split(',').indexOf(opt) != -1 : false
  }

  clearFormControl() {
    this.formControl.setValue(null)
  }

  onChange(event, value?) {
    if (this.type === 'file') {
      this.handleInputChange(event)
        .then((data: any[]) => {
          this.writeValue(data);
          this.propagateChange(data);
          if (this.returnBase64) {
            if (this.multipleFiles) {
              this.formControl.setValue(data.map(file => file.data.result))
            } else {
              let file = data.map(file => file.data.result)[0]

              this.formControl.setValue(file);
            }
          } else {
            this.formControl.setValue(data);
          }
          if (this.multipleFiles) {
            this.fileName = data.map(file => file.original.name).join(', ')
          }
        },
          (error) => {
            this.formControl.markAsTouched();
            this.formControl.setErrors({ 'invalid_size': true })
          });
    } else if (this.type === 'checkboxGroup') {
      if (event.checked) {
        let val: string = this.formControl.value;
        if (val) {
          if (val.indexOf(value) == -1) {
            if (val) {
              this.formControl.setValue(val + ',' + value)
            } else {
              this.formControl.setValue(value)
            }
          }
        } else {
          this.formControl.setValue(value)
        }
      } else {
        let val: string = this.formControl.value;
        if (val.indexOf(',') > val.indexOf(value)) {
          this.formControl.setValue(val.replace(value + ',', ''))
        } else {
          if (val.indexOf(',') == -1) {
            this.formControl.setValue(val.replace(value, ''))
          } else {
            this.formControl.setValue(val.replace(',' + value, ''))
          }
        }
      }
    } else if (this.type === 'checkbox') {
      this.writeValue(event.checked);
      this.formControl.setValue(event.checked)
      this.propagateChange(event.checked);
    } else if (this.type === 'number') {
      this.writeValue(event.checked);
      let numberValue = event.target.value;
      if (typeof numberValue === 'string') {
        numberValue = parseInt(numberValue)

        if (isNaN(numberValue)) {
          numberValue = null;
        }
      }
      this.formControl.setValue(numberValue)
      if (this.max || this.min) {
        this.formControl.setValidators([Validators.required]);
      }
      if (this.max && this.formControl.value > this.max) {
        this.formControl.setValue(this.max);
        this.formControl.setValidators([Validators.required, Validators.max(this.max)]);
        this.formControl.setErrors({ 'max': { 'actualLength': this.formControl.value, 'requiredLength': this.max } })
        this.formControl.markAsTouched()
        this.formControl.invalid
      }
      if (this.min && this.formControl.value < this.min) {

        this.formControl.setValidators([Validators.required, Validators.min(this.min)])
        this.formControl.setErrors({ 'min': { 'actualLength': this.formControl.value, 'requiredLength': this.min } })
        this.formControl.invalid
        this.formControl.markAsTouched()
      }
    } else if (this.type === 'switch') {
      this.formControl.setValue(event.target.checked)
      this.writeValue(event.target.checked);
      this.propagateChange(event.target.checked);
    } else if (this.type === 'date') {
      this.formControl.setValue(event.target.value)
      this.defaultinput.nativeElement.offsetParent.classList.add('filled');
      this.writeValue(event.target.value);
      this.propagateChange(event.target.value);
    } else if (this.type === 'switch') {
      this.formControl.setValue(this.check)
    } else if (this.type === 'radio' || this.type === 'slider') {
      this.formControl.setValue(this.value)
    } else if (this.type === 'textarea') {
      this.formControl.setValue(event.target.value)
    }
    else if (this.type === 'phone') {
      this.formControl.setValue(event.target.value)
      this.writeValue(event.target.value);
      this.propagateChange(event.target.value);
    }
    else {
      setTimeout(() => {
        this.writeValue(event.target.value);
        this.propagateChange(event.target.value);
      });
    }
  }

  onNgSelectChange(event) {
    setTimeout(() => {
      this.defaultinput.element.offsetParent ? this.defaultinput.element.offsetParent.classList.add('filled') : null
      this.writeValue(event ? event.value : null);
      this.propagateChange(event ? event.value : null);
    });
    if (event) {
      this.formControl.setValue(event.value);
    }
    // this.formControl.markAsTouched();
    this.dataExpand = false;
    this.expand.emit({ status: false });
  }

  onKeyup(event) {
    if (this.type == 'phone') {
      this.switchPhoneMask(event.target.value.length)
    }

    this.writeValue(event.target.value);
    this.propagateChange(event.target.value);

    if (this.type == 'text' || this.type == 'phone' || this.type == 'textarea') {
      this.formControl.setValue(event.target.value);
    }
  }

  onKeypress(event) {
    this.keyup.emit(event)
    if (this.type === 'number') {
      if (isNaN(parseInt(event.key, 10))) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  blur(event) {
    this.blurEmitter.emit(true);
    if (this.type === 'date') {

      console.log(this.datepicker)
      const ne = this.datepicker._nativeElement;
      (<HTMLElement>this.datepicker._nativeElement.parentElement).classList.add('filled')
      if (!isDescendant(ne, event.relatedTarget) && event.relatedTarget !== ne) {
        this.hideCalendar = true;
        this.formControl.markAsTouched();
        this.dataExpand = false
        this.expand.emit({ status: false })
      }
    } else {
      this.formControl.markAsTouched();
    }
    if (this.type === 'text' || this.type === 'textarea') {
      this.formControl.setValue(event.target.value)
    }
    if (this.type == 'phone') {

      this.switchPhoneMask(event.target.value.length)

      this.writeValue(event.target.value);
      this.propagateChange(event.target.value);
      this.formControl.setValue(event.target.value);
    }
  }

  step(event, index, type?) {
    if (this.max && this.value > this.max && type == 'plus') {
      return;
    }
    if (!this.value) {
      this.value = '0';
    }
    if (parseInt(this.value, 10) + index >= 0) {
      this.value = parseInt(this.value, 10) + index;
    }

    this.writeValue(this.value);
    this.propagateChange(this.value);
    this.formControl.setValue(this.value)
    if (this.max && this.value > this.max) {
      this.formControl.setValidators([Validators.max(this.max)]);
      this.formControl.setErrors({ 'max': { 'actualLength': this.formControl.value, 'requiredLength': this.max } })
    }
    if (this.min && this.value < this.min) {

      this.formControl.setValidators([Validators.min(this.min)])
      this.formControl.setErrors({ 'min': { 'actualLength': this.formControl.value, 'requiredLength': this.min } })
    }
    this.formControl.markAsTouched();
    event.preventDefault();
  }

  focus(event) {
    this.focusEmitter.emit(true);
    if (this.type === 'date') {
      this.hideCalendar = false;
      this.defaultinput.nativeElement.focus();
      if (this.value) {
        const arrayDate = this.value.split('/').map(item => parseInt(item, 10));
        this.datepicker.navigateTo({ year: arrayDate[2], month: arrayDate[1], day: arrayDate[0] });
      }
      this.dataExpand = true;
      this.expand.emit({ status: true })
    }

  }

  expanded(status: boolean) {
    this.expand.emit({ status })
    this.dataExpand = status
  }

  dateSelect(event) {
    const pad = '00';
    const day = '' + event.day;
    const month = '' + event.month;
    this.onChange({
      target: {
        value: pad.substring(0, pad.length - day.length) + day
          + '/' + pad.substring(0, pad.length - month.length) + month
          + '/' + event.year
      }
    });
    this.hideCalendar = true;
    this.dataExpand = false;
    this.expand.emit({ status: false })
  }

  /* color */
  onColorPickerSelect(color) {
    if (color) {
      this.value = color;
      this.formControl.setValue(color)
      this.formControl.markAsTouched();
      setTimeout(() => {
        this.writeValue(color);
        this.propagateChange(color);
      });
    }
  }

  onColorPickerClose() {
    this.formControl.markAsTouched();
  }

  /* file */
  handleInputChange(event) {
    if (this.multipleFiles) {
      const promises = Array.prototype.map.call(event.target.files, file => {
        return toDataURL(file).then((data: any) => {
          return { data, original: file };
        });
      });

      return new Promise((resolve) => {
        Promise.all(promises).then(files => files)
          .then(files => {
            this.formControl.markAsTouched();
            resolve(files);
          });
      });

    } else {
      const file = event.target.files[0];
      this.hasFile = file.name
      if (validateMBsize(file.size, file.type)) {
        return Promise.reject(false)
      }
      if (file) {
        return toDataURL(file)
          .then((data: any) => {
            this.formControl.markAsTouched();
            return [{ data, original: file }];
          });
      } else {
        // this.fileRead.emit(false);
      }
    }

  }

  openDialog(event) {
    event.preventDefault();
    const eventClick = new MouseEvent('click', { bubbles: true });
    if (this.defaultinput) {
      this.defaultinput.nativeElement.value = null;
      this.defaultinput.nativeElement.dispatchEvent(eventClick);
    }
  }

  removeSelectedFile(event, index) {
    this.value.splice(index, 1);
    const newArray = [...this.value];
    this.formControl.markAsTouched();
    this.writeValue(newArray);
    this.propagateChange(newArray);
  }

  droppedFiles(event) {
    if (!this.multipleFiles && event.files.length > 1) {
      return;
    }

    const promises = event.files.map(file => {
      return new Promise(resolve =>
        file.fileEntry.file(info => resolve(info))
      );
    });

    Promise.all(promises).then(files => {
      this.handleInputChange({ target: { files } })
        .then(data => {
          this.writeValue(data);
          this.propagateChange(data);
        });
    });

  }

  removeFiles(indexes: Array<number>) {
    // indexes.forEach( index => this.removeSelectedFile({}, index));
  }

  switchPhoneMask(phone: number) {
    if (phone > 14) {
      this.phoneMask = '(00) 00000-0000';
    } else {
      this.phoneMask = '(00) 0000-00009';
    }
  }

  canSeePassword() {
    this.passwordVisible = !this.passwordVisible;
      if(this.passwordVisible){
        this.iconEye = 'fa-eye';
        this.showHidePassword.emit('text');
      }else{
        this.iconEye = 'fa-eye-slash';
        this.showHidePassword.emit('password');
      }
  }

}


export function getMaxSizeByFileType(type: string): number {

  switch (type) {
    case 'image/jpg':
      return 3
    case 'image/png':
      return 3
    case 'image/jpeg':
      return 3
    case 'image/gif':
      return 10
    case 'video/mp4' || 'video/mov' || 'video/wmv' || 'video/avi':
      return 80
    default:
      return 30
  }

}

export function validateMBsize(bytes: number, type: string) {

  let max_size: number = getMaxSizeByFileType(type);

  if (bytes === 0) return null;

  if (bytes >= 1048576) {
    bytes = parseFloat((bytes / 1048576).toFixed(2))
    if (bytes > max_size) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
